export const TRANSFER_STATUS = {
  transferredPending: 'transferred - pending',
  transferredReported: 'transferred - reported',
  transferredCompleted: 'transferred - completed',
  transferredUnassigned: 'transferred - unassigned',
  transferredAssigned: 'transferred - assigned',
  transferredClosed: 'transferred - closed',
  transferred: 'transferred',
};

export const TASK_STATUS = {
  missing_info: 'missing info',
  reported: 'reported',
  unassigned: 'unassigned',
  assigned: 'assigned',
  accepted: 'accepted',
  pending: 'pending',
  invalidated: 'invalidated',
  cancelled: 'cancelled',
  completed: 'completed',
  declined: 'declined',
  closed: 'closed',
  ...TRANSFER_STATUS,
};
