import { t } from 'i18next';
import moment from 'moment';
import { Trans } from 'react-i18next';

import { MAX_AMOUNT_OF_SELECTED_TASKS } from '@yojee/helpers/constants';
import { isCancelledTask, isCompletedTask } from '@yojee/helpers/TasksHelper';
import { getTaskState } from '@yojee/helpers/TaskStateHelper';
import { utcLocalFormat } from '@yojee/helpers/TimeHelper';

import { TASK_STATUS } from './taskConstants';
import {
  getTransferredStatus,
  TRANSFER_STATUS_KEY_TEXT_MAP,
  transferredStatusTextRawGetterMap,
} from './TransferredHelper';

export const convertStopsToTreeNodes = (stops) => {
  return stops.map((stop, idx) => {
    return convertStopToTreeNode(stop, idx);
  });
};

export const convertStopsToTreeNodes_normalized = (stops, ids) => {
  return ids.map((stopId, idx) => {
    const stop = stops[stopId];
    return convertStopToTreeNode(stop, idx);
  });
};

const convertStopToTreeNode = (stop, idx) => {
  const result = {
    id: stop.id,
    stop: { ...stop, currentIdx: idx },
    name: stop.id,
    state: { expanded: false, favorite: false, deletable: false },
  };
  if (stop.tasks && stop.tasks.length > 1) {
    result['children'] = stop.tasks.map((t) => {
      return { id: `node-${stop.id}-${t.id}`, task: t, stop };
    });
  }
  return result;
};

export const getStopAddress = (stop, { isShowSenderName, isShowCompanyName }) => {
  const address = stop?.location?.address || stop?.['order_step']?.address || '';
  const address2 = stop?.['order_step']?.address2 ?? '';
  const postalCode = stop?.['order_step']?.postal_code ?? '';
  const state = stop?.['order_step']?.state ?? '';
  const country = stop?.['order_step']?.country ?? '';
  const parts = [
    stop?.['order_step']?.['contact_name'],
    isShowSenderName ? stop?.tasks?.[0]?.sender?.['organisation_name'] ?? stop?.tasks?.[0]?.sender?.name : null,
    isShowCompanyName ? stop?.['order_step']?.['contact_company'] : null,
  ]
    .filter((p) => p)
    .map((p) => `${p}, `);

  return `${parts.join('')}${address} ${address2} ${postalCode} ${state}  ${country}`.replace(/\s+/g, ' ');
};

export const getStopState = (stop) => {
  if (stop.actual_end) {
    return stop.state;
  } else {
    // TODO: needs to be fixed. Not sure why it is so nested
    const taskState = stop?.tasks[0]?.task?.state || stop?.tasks[0]?.task?.task?.state;
    const task = stop.tasks[0]?.task;

    if (stop.tasks[0].transfer_requested_time) {
      return 'transferred';
    }

    if (taskState === 'created' && task?.closed_at) {
      return 'closed';
    }

    if (taskState === 'invalidated' && task?.cancelled_time) {
      return 'cancelled';
    }

    if (taskState === 'created' && stop?.tasks[0]?.task_group?.state === 'assigned') {
      return 'assigned';
    }

    if (taskState === 'completed') {
      return 'completed';
    }

    return 'unassigned';
  }
};

export const calculateAddressHeight = (stop) => {
  const address = getStopAddress(stop, {});
  const element = document.createElement('canvas');
  element.style.fontSize = '15px';
  const context = element.getContext('2d');
  context.font = '15px Arial';
  const width = context.measureText(address).width;
  const maxWith = 288;
  const ratio = width / maxWith;
  const height = 19 * (ratio > 2 ? 3 : ratio > 1 ? 2 : 1);
  return height;
};

export const getCompletionDate = (stop) => {
  return utcLocalFormat(stop.tasks[0].task.completion_time);
};

export const formatDateRange = (from, to) => {
  if (!to) return `${moment.utc(from).format('DD MMMM YYYY HH:mm')}`;
  const diffDays = moment.utc(to).diff(moment.utc(from), 'days');
  return diffDays > 0
    ? `${utcLocalFormat(from)} - ${utcLocalFormat(to)}`
    : `${utcLocalFormat(from)} - ${moment.utc(to).local().format('HH:mm')}`;
};

export const allAvailableSelectTasks = ({ data, ids }) => {
  const selectedTasks = [];
  ids.some((id) => {
    const task = data[id];
    if (
      (data[id].transfer_state ||
        data[id].task_group.state === 'unassigned' ||
        data[id].task_group.state === 'assigned') &&
      (data[id].task.state !== 'invalidated' ||
        (data[id].transfer_state !== 'completed' &&
          data[id]?.['transfer_task_metadata']?.['task_info']?.state !== 'completed')) &&
      !isCompletedTask(task) &&
      !isCancelledTask(task)
    ) {
      selectedTasks.push(id);
    }

    return selectedTasks.length === MAX_AMOUNT_OF_SELECTED_TASKS;
  });
  return selectedTasks;
};

export const filterReportableTasks = (tasks) => {
  return tasks.filter((task) => getTaskStatus(task) === TASK_STATUS.assigned);
};

export const filterCompletableTasks = (tasks) => {
  return tasks.filter((task) => [TASK_STATUS.assigned, TASK_STATUS.closed].includes(getTaskStatus(task)));
};

export const getAllowedActionsForStopTasks = (
  tasks,
  { allowCancelWithAssignedTask, allowCancelWithCompletedTasks, canBroadCastSettings, tasksGroupsBroadcastIds }
) => {
  if (!tasks) {
    return [];
  }

  const abilities = [];

  let cancellable = false;
  if (allowCancelWithCompletedTasks) {
    const allTasksCompleted = tasks.every((t) => getTaskStatus(t) === 'completed');

    cancellable =
      !allTasksCompleted &&
      tasks.find((t) => {
        if (!t || !t.task) return false;
        const taskState = getTaskStatus(t);
        return (
          ['unassigned', 'missing info', 'reported', 'closed'].includes(taskState) ||
          (taskState === 'assigned' && allowCancelWithAssignedTask) ||
          (taskState === 'completed' && allowCancelWithCompletedTasks)
        );
      });
  } else {
    cancellable =
      !tasks.find((t) => getTaskStatus(t) === 'completed') &&
      tasks.find((t) => {
        if (!t || !t.task) return false;
        const taskState = getTaskStatus(t);
        return (
          ['unassigned', 'missing info', 'reported', 'closed'].includes(taskState) ||
          (taskState === 'assigned' && allowCancelWithAssignedTask)
        );
      });
  }

  const completable = filterCompletableTasks(tasks).length > 0;
  const reportable = filterReportableTasks(tasks).length > 0;

  if (cancellable) {
    abilities.push('Cancel');
  }

  if (completable) {
    abilities.push('Complete');
  }

  if (reportable) {
    abilities.push('Report');
  }

  if (canBroadCastSettings && tasksGroupsBroadcastIds && tasksGroupsBroadcastIds.length > 0) {
    abilities.push('Broadcast');
  }

  return abilities;
};

export const getTaskStatus = (task) => {
  if (task?.transfer_requested_time) {
    return getTransferredStatus(task);
  }

  return getTaskState(task);
};

export const TASK_STATUS_KEY_TEXT_MAP = {
  [TASK_STATUS.missing_info]: <Trans>missing info</Trans>,
  [TASK_STATUS.reported]: <Trans>reported</Trans>,
  [TASK_STATUS.unassigned]: <Trans>unassigned</Trans>,
  [TASK_STATUS.assigned]: <Trans>assigned</Trans>,
  [TASK_STATUS.accepted]: <Trans>accepted</Trans>,
  [TASK_STATUS.pending]: <Trans>pending</Trans>,
  [TASK_STATUS.invalidated]: <Trans>invalidated</Trans>,
  [TASK_STATUS.cancelled]: <Trans>cancelled</Trans>,
  [TASK_STATUS.completed]: <Trans>completed</Trans>,
  [TASK_STATUS.closed]: <Trans>closed</Trans>,
  ...TRANSFER_STATUS_KEY_TEXT_MAP,
};

const taskStatusTextRawGetterMap = {
  [TASK_STATUS.missing_info]: () => t('missing info'),
  [TASK_STATUS.reported]: () => t('reported'),
  [TASK_STATUS.unassigned]: () => t('unassigned'),
  [TASK_STATUS.assigned]: () => t('assigned'),
  [TASK_STATUS.accepted]: () => t('accepted'),
  [TASK_STATUS.pending]: () => t('pending'),
  [TASK_STATUS.invalidated]: () => t('invalidated'),
  [TASK_STATUS.cancelled]: () => t('cancelled'),
  [TASK_STATUS.completed]: () => t('completed'),
  ...transferredStatusTextRawGetterMap,
};

export const getTaskStatusTextRaw = (status) => {
  const getter = taskStatusTextRawGetterMap[status];
  if (!getter) return status;
  return getter();
};

export const getColorByTaskStatus = (status) => {
  switch (status) {
    case TASK_STATUS.missing_info:
      return '#ff5800';
    case TASK_STATUS.reported:
      return '#ff5800';
    case TASK_STATUS.unassigned:
      return '#2f7ae2';
    case TASK_STATUS.assigned:
      return '#0045b3';
    case TASK_STATUS.accepted:
      return '#0045b3';
    case 'broadcasting':
      return '#ffe900';
    case TASK_STATUS.transferred:
      return '#1b02d0';
    case TASK_STATUS.transferredPending:
      return '#2f7ae2';
    case TASK_STATUS.transferredUnassigned:
      return '#2f7ae2';
    case TASK_STATUS.transferredAssigned:
      return '#0045b3';
    case TASK_STATUS.transferredReported:
      return '#ff5800';
    case TASK_STATUS.transferredCompleted:
      return '#72c205';
    case TASK_STATUS.transferredClosed:
      return '#d0021b';
    case TASK_STATUS.completed:
      return '#72c205';
    case TASK_STATUS.pending:
      return '#d0021b';
    case TASK_STATUS.invalidated:
      return '#d0021b';
    case TASK_STATUS.cancelled:
      return '#d0021b';
    case TASK_STATUS.closed:
      return '#d0021b';
    default:
      return '#0045b3';
  }
};

export const getColorByPlanningStatus = (planningStatus) => {
  switch (planningStatus) {
    case 'unassigned':
    case 'declined':
      return '#d0021b';
    case 'transfer_pending':
    case 'transferred':
      return '#2e60be';
    case 'completed':
      return '#72c205';
    case 'cancelled':
      return '#686b7a';
    default:
      return '#040921';
  }
};

export const getIsShowEditAfterPlanningWarning = (task) => {
  const taskStatus = getTaskStatus(task);

  return !!task.order_item.after_planning_updated_at && !!taskStatus && !taskStatus.includes('cancelled');
};
